@font-face {
  font-family: "Neue Montreal";
  src: url("/Users/gracesanford/Documents/personal-dev/portfolio/public/fonts/NeueMontreal-Regular.otf");
  crossorigin: "anonymous";
}
@font-face {
  font-family: "Neue Montreal Bold";
  src: url("/Users/gracesanford/Documents/personal-dev/portfolio/public/fonts/NeueMontreal-Bold.otf");
  crossorigin: "anonymous";
}
@font-face {
  font-family: "Neue Montreal Light";
  src: url("/Users/gracesanford/Documents/personal-dev/portfolio/public/fonts/NeueMontreal-Light.otf");
  crossorigin: "anonymous";
}
@font-face {
  font-family: "Neue Montreal Medium";
  src: url("/Users/gracesanford/Documents/personal-dev/portfolio/public/fonts/NeueMontreal-Medium.otf");
  crossorigin: "anonymous";
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: Neue Montreal, sans-serif;
}



