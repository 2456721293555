.App {
  display: flex;
  flex-direction: column;
}
/* 
.homepage-btn {
  align-self: center;
}
*/
.homepage-header {
  align-self: center;
  font-size: 50px;
  margin-top: 8rem;
} 


/* ---------------------------Colors---------------------------- */
.lightpurple {
  background-color: #d290d2;
}

.turquoise {
  background-color: #66cdaa;
}

.cyan {
  background-color: #00ffff;
}

.pink {
  background-color: #ffc0cb;
}

.lightblue {
  background-color: #add8e6;
}

.yellowgreen {
  background-color: #adff2f;
}

.gold {
  background-color: #ffdd00;
}
/* ---------------------------end Colors---------------------------- */

/* ---------------------------Embeded---------------------------- */
.gallery-house-img, .clutter-img {
  padding: 1rem;
  margin-top: 0rem;
  max-width: 35rem;
  width: 375px;
  /* position: relative; */
  margin-left: auto;
  margin-right: auto;
}

/* .videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  margin-top: 4rem;
  padding-right: 0;
  height: 0;
}

.vid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */

/* 
.git-clothes-img {
  max-width: 35rem;
  width: 375px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.list-group {
  margin-top: 5rem;
} */

/* ---------------------------end Embeded------------------------- */

.navContainer {
  align-self: center;
  display: flex;
  flex-direction: row;
  position: fixed;
  margin-top: 0px;
  background-color: white;
  border-radius: 25px;
  z-index: 1;
}

.sectionGH {
  margin-top: 2.5rem;
}




/* 
.custom-navbar .navbar-toggler {
  width: 50px;
}

@media (max-width: 767px) {
  .custom-navbar .navbar-collapse {
    display: flex;
    justify-content: center;
  }

  .custom-navbar .navbar-nav {
    margin: 0 auto;
  }
}

.nav {
  background-color: lightblue;
}

#nav-dropdown,
#nav-dropdown-item,
#nav-item {
  font-size: 1.5rem;
  background-color: lightblue;
  color: rgb(39, 34, 34);
} */

.text {
  align-items: center;
  margin-top: 10rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}

.text-red {
  color: rgb(252 165 165);
}

#clutter {
  margin-bottom: 10rem;
}

.section {
  margin-top: 28rem;
}

.sectionLinks {
  margin-top: 20rem;
  margin-bottom: 20rem;
}

h3 {
  align-self: center;
}

.alert {
  margin-top: 4rem;
  width: 375px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.active-link {
  color: #000;
  font-weight: bold;
  text-decoration: underline;
}